import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Badge, Dropdown, Modal } from "react-bootstrap";
import { ButtonComponent } from "../ButtonComponent";
import {
  createDocument,
  dataURLtoFile,
  getUsers,
  optimizeImage,
  optimizePDF,
  truncateString,
  updateApplication,
  updateDocument,
  updateStudent,
} from "../../Services/Function";

import { UploadInput, UploadSubtext, UploadText } from "../DocumentPage/style";

import { useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { dataAction } from "../../state";
import { useDispatch } from "react-redux";

type Props = {
  show: any;
  handleClose: any;
  application: any;
  reloadApplication: any;
  studentId: any;
  unconditionalOffer?: any;
  reloadStudents: any;
  documents: any;
  reloadDocuments: any;
};

export const ReadyToApply = (props: Props) => {
  const [users, setUsers] = useState([]);
  const [managedBy, setmanagedBy] = useState<any>({});
  const [filePreviewUrls, setFilePreviewUrls] = React.useState<
    (string | ArrayBuffer)[]
  >([]);
  const [loading, setloading] = useState(false);
  const [isImage, setIsImage] = React.useState<boolean[]>([]);
  const [isPdf, setIsPdf] = React.useState<boolean[]>([]);
  const [imageAsFile, setimageAsFile] = React.useState<any>([]);
  const [selectedStatus, setselectedStatus] = useState("");
  const dispatch = useDispatch();

  const auth = useSelector((state: any) => state.auth);
  const data = useSelector((state: any) => state.data);
  const { handleFileUpload, setUploading } = bindActionCreators(
    dataAction,
    dispatch
  );

  React.useEffect(() => {
    async function fetchData() {
      try {
        const user = await getUsers();
        setUsers(user.results);
      } catch (error) {
        alert(error);
      }
    }
    fetchData();
  }, []);

  async function handleImageAsFile(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedFiles = event.target.files;

    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        const selectedFile = selectedFiles[i];
        const reader = new FileReader();
        reader.onload = async () => {
          // Add file preview and type checks
          setFilePreviewUrls((prevFilePreviewUrls: any) => [
            ...prevFilePreviewUrls,
            reader.result,
          ]);
          setIsImage((prevIsImage: any) => [
            ...prevIsImage,
            selectedFile.type.includes("image"),
          ]);
          setIsPdf((prevIsPdf: any) => [
            ...prevIsPdf,
            selectedFile.type.includes("pdf"),
          ]);

          // Directly add the selected file to state without optimization
          if (selectedFile.type.includes("pdf")) {
            try {
              setimageAsFile((prev: any) => [...prev, selectedFile]);
            } catch (uploadError) {
              console.error("Error uploading PDF:", uploadError);
            }
          } else if (selectedFile.type.includes("image")) {
            try {
              setimageAsFile((prev: any) => [...prev, selectedFile]);
            } catch (uploadError) {
              console.error("Error uploading image:", uploadError);
            }
          }
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  }

  const bg = [
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
  ];

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        // id="modal-right"
        className="modal fade"
        centered
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            {props.application?.portalApplicationStatus?.applicationPhases.filter(
              (stage: { phaseState: string }) =>
                stage.phaseState === "AwaitingResponseStudent"
            )[0].status === "Submitted"
              ? "Ready to Apply"
              : props.unconditionalOffer
                ? "Unconditional offer"
                : props.application?.portalApplicationStatus?.applicationPhases.filter(
                    (stage: { phaseState: string }) =>
                      stage.phaseState === "AwaitingResponseStudent"
                  )[0].status}
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={{}}
          onSubmit={async function () {
            try {
              let stages: any =
                props.application?.portalApplicationStatus?.applicationPhases;
              setloading(true);

              let resp, updateDoc;
              // Step 1: Handle the "Submitted" status and update the application
              if (
                stages.filter(
                  (stage: { phaseState: string }) =>
                    stage.phaseState === "AwaitingResponseStudent"
                )[0].status === "Submitted"
              ) {
                await updateApplication(props.application._id, {
                  managedBy: managedBy.id,
                  portalApplicationStatus: { applicationPhases: stages },
                });
              }
              let uploadTask;
              // Step 2: Start the file upload in the background

              if (imageAsFile.length) {
                uploadTask = handleFileUpload(imageAsFile);
              }

              // Step 3: Process conditional offer logic
              if (
                stages.filter(
                  (stage: { phaseState: string }) =>
                    stage.phaseState === "AwaitingResponseStudent"
                )[0].status === "Conditional offer" &&
                !props.unconditionalOffer
              ) {
                updateDoc = props.documents.filter(
                  (z: any) => z.subType === "Conditional offer"
                );

                if (!updateDoc.length) {
                  resp = await createDocument({
                    documentType: "Issued Document",
                    subType: "Conditional offer",
                    tag: {},
                    applicationId: props.application._id,
                    docState: "Not Uploaded", // Placeholder state until upload completes
                    blobInfo: [], // Empty for now, will be updated later
                    studentId: props.studentId,
                  });
                }
              }

              // Step 4: Process unconditional offer logic
              if (
                stages.filter(
                  (stage: { phaseState: string }) =>
                    stage.phaseState === "AwaitingResponseStudent"
                )[0].status === "Unconditional offer" ||
                (stages.filter(
                  (stage: { phaseState: string }) =>
                    stage.phaseState === "AwaitingResponseStudent"
                )[0].status === "Conditional offer" &&
                  props.unconditionalOffer)
              ) {
                updateDoc = props.documents.filter(
                  (z: any) => z.subType === "Unconditional offer"
                );

                // Update the "Unconditional offer" stage's phaseState and isCurrent
                const unconditionalOfferStage = stages.find(
                  (stage: any) => stage.status === "Unconditional offer"
                );

                if (unconditionalOfferStage) {
                  unconditionalOfferStage.phaseState =
                    "AwaitingResponseStudent";
                  unconditionalOfferStage.isCurrent = true;
                }

                const submittedStage = stages.find(
                  (stage: any) => stage.status === "Submitted"
                );

                if (submittedStage) {
                  submittedStage.phaseState = "Completed";
                  submittedStage.isCurrent = false;
                  submittedStage.isPrevious = false; // Set isPrevious to false as per the new requirement
                }
                // Update the previous "Conditional offer" stage to "Completed"
                const conditionalOfferStage = stages.find(
                  (stage: any) => stage.status === "Conditional offer"
                );

                if (conditionalOfferStage) {
                  conditionalOfferStage.phaseState = "Completed";
                  conditionalOfferStage.isCurrent = false;
                  conditionalOfferStage.isPrevious = true;
                }

                if (!updateDoc.length) {
                  resp = await createDocument({
                    documentType: "Issued Document",
                    subType: "Unconditional offer",
                    tag: {},
                    applicationId: props.application._id,
                    docState: "Not Uploaded", // Placeholder state until upload completes
                    blobInfo: [], // Empty for now, will be updated later
                    studentId: props.studentId,
                  });
                }
              }

              // Step 5: Process other statuses (Confirmation, FG/BS, CAS Received)
              if (
                stages.filter(
                  (stage: { phaseState: string }) =>
                    stage.phaseState === "AwaitingResponseStudent"
                )[0].status === "Confirmation"
              ) {
                await updateApplication(props.application._id, {
                  status: selectedStatus,
                });
              }

              if (
                stages.filter(
                  (stage: { phaseState: string }) =>
                    stage.phaseState === "AwaitingResponseStudent"
                )[0].status === "FG/BS"
              ) {
                updateDoc = props.documents.filter(
                  (z: any) => z.subType === "FG/BS"
                );
                if (!updateDoc.length) {
                  resp = await createDocument({
                    documentType: "Issued Document",
                    subType: "FG/BS",
                    tag: {},
                    applicationId: props.application._id,
                    docState: "Not Uploaded", // Placeholder state until upload completes
                    blobInfo: [], // Empty for now, will be updated later
                    studentId: props.studentId,
                  });
                }
              }

              if (
                stages.filter(
                  (stage: { phaseState: string }) =>
                    stage.phaseState === "AwaitingResponseStudent"
                )[0].status === "CAS Received"
              ) {
                updateDoc = props.documents.filter(
                  (z: any) => z.subType === "CAS Received"
                );
                if (!updateDoc.length) {
                  resp = await createDocument({
                    documentType: "Issued Document",
                    subType: "CAS Received",
                    applicationId: props.application._id,
                    tag: {},
                    docState: "Not Uploaded", // Placeholder state until upload completes
                    blobInfo: [], // Empty for now, will be updated later
                    studentId: props.studentId,
                  });
                }
              }

              // Step 6: Handle confirmation stage logic and update student/application
              const confirmationStage = stages.find(
                (stage: any) => stage.status === "Confirmation"
              );
              if (
                confirmationStage.phaseState === "AwaitingResponseStudent" ||
                confirmationStage.phaseState === "Completed"
              ) {
                await updateStudent(props.studentId, {
                  onHold: true,
                });
                await updateApplication(props.application._id, {
                  finalChoice: true,
                });
              } else if (confirmationStage.phaseState === "Upcoming") {
                await updateApplication(props.application._id, {
                  finalChoice: false,
                });
                await updateStudent(props.studentId, {
                  onHold: false,
                });
              }

              // Step 7: Final application update
              await updateApplication(props.application._id, {
                portalApplicationStatus: { applicationPhases: stages },
                phaseChanged: true,
                editor: auth.userDetails,
              });

              // Step 8: Close modal and reset loading state
              setimageAsFile([]);
              props.reloadApplication();
              props.reloadStudents();
              setloading(false);
              props.handleClose();

              if (uploadTask) {
                setUploading(updateDoc.length ? updateDoc[0].id : resp.id);
                // Step 9: Once upload completes, update the document(s)
                const downloadURL: any = await uploadTask;
                if (downloadURL?.length) {
                  const blobInfo = downloadURL.map((a: any) => ({
                    blobUrl: a.url,
                    name: a.name,
                  }));

                  // Update the relevant documents with the actual blob info and uploaded state
                  // Example update for a document created earlier:
                  if (updateDoc.length) {
                    await updateDocument(updateDoc[0].id, {
                      docState:
                        updateDoc[0].blobInfo?.length || downloadURL.length
                          ? "Upload"
                          : "Not Uploaded",
                      blobInfo: [...updateDoc[0].blobInfo, ...blobInfo],
                    });
                  } else {
                    await updateDocument(resp.id, {
                      docState: "Upload",
                      blobInfo: blobInfo,
                    });
                  }

                  props.reloadDocuments();
                  setUploading(false);
                }
                // Additional updates for application or student records can go here if needed
              }
            } catch (error) {
              console.log(error);
              setloading(false);
            }
          }}
        >
          {({ values }) => (
            <Form className="d-flex flex-column" style={{ marginTop: 20 }}>
              <Modal.Body>
                {props.application?.portalApplicationStatus?.applicationPhases.filter(
                  (stage: { phaseState: string }) =>
                    stage.phaseState === "AwaitingResponseStudent"
                )[0].status === "Submitted" ? (
                  <>
                    <Dropdown>
                      <Dropdown.Toggle
                        className=" form-input"
                        id="dropdown-custom-components"
                      >
                        {managedBy.name || "Filled By"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="dd-custom-menu">
                        {users.map((a: any) => (
                          <Dropdown.Item
                            key={a.id}
                            className="dd-custom-items"
                            onClick={async () => {
                              setmanagedBy(a);
                            }}
                          >
                            {a.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                ) : props.application?.portalApplicationStatus?.applicationPhases.filter(
                    (stage: { phaseState: string }) =>
                      stage.phaseState === "AwaitingResponseStudent"
                  )[0].status === "Conditional offer" &&
                  !props.unconditionalOffer ? (
                  <>
                    <UploadInput>
                      <input
                        style={{
                          height: "100%",
                          opacity: 0,
                          width: "100%",
                          position: "absolute",
                          cursor: "pointer",
                        }}
                        type="file"
                        accept=".pdf,.jpg,.jpeg,.png"
                        onChange={(e: any) => handleImageAsFile(e)}
                      />

                      <UploadText>Upload Document Conditional</UploadText>
                      <UploadSubtext>
                        Drag and drop your file here or click to upload a local
                        file
                      </UploadSubtext>
                    </UploadInput>
                    {filePreviewUrls.length > 0 && (
                      <div
                        style={{ marginTop: 10 }}
                        className="d-flex flex-column"
                      >
                        {filePreviewUrls.map((filePreviewUrl: any, i: any) => {
                          return (
                            <div
                              style={{ marginRight: 5, marginBottom: 5 }}
                              key={i}
                            >
                              <div
                                onClick={() => {
                                  const newData = [...filePreviewUrls];
                                  newData.splice(i, 1);
                                  setFilePreviewUrls(newData);
                                }}
                                style={{
                                  position: "absolute",
                                  background: "#fff",
                                  width: 20,
                                  height: 20,
                                  borderRadius: "50%",
                                  // top: -10,
                                  // left: -10,
                                }}
                                className="d-flex justify-content-center align-items-center"
                              >
                                X
                              </div>
                              {isImage[i] ? (
                                <img
                                  src={filePreviewUrl as string}
                                  alt="Preview"
                                  style={{
                                    width: 50,
                                    height: 70,
                                    borderRadius: 8,
                                  }}
                                />
                              ) : isPdf[i] ? (
                                // eslint-disable-next-line jsx-a11y/iframe-has-title
                                <p style={{ marginLeft: 25 }}>
                                  {truncateString(imageAsFile[i]?.name)}
                                </p>
                              ) : (
                                <p>File type not supported</p>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                ) : props.application?.portalApplicationStatus?.applicationPhases.filter(
                    (stage: { phaseState: string }) =>
                      stage.phaseState === "AwaitingResponseStudent"
                  )[0].status === "Unconditional offer" ||
                  (props.application?.portalApplicationStatus?.applicationPhases.filter(
                    (stage: { phaseState: string }) =>
                      stage.phaseState === "AwaitingResponseStudent"
                  )[0].status === "Conditional offer" &&
                    props.unconditionalOffer) ? (
                  <>
                    <UploadInput>
                      <input
                        style={{
                          height: "100%",
                          opacity: 0,
                          width: "100%",
                          position: "absolute",
                          cursor: "pointer",
                        }}
                        type="file"
                        accept=".pdf,.jpg,.jpeg,.png"
                        onChange={(e: any) => handleImageAsFile(e)}
                      />

                      <UploadText>Upload Document Unconditional</UploadText>
                      <UploadSubtext>
                        Drag and drop your file here or click to upload a local
                        file
                      </UploadSubtext>
                    </UploadInput>
                    {filePreviewUrls.length > 0 && (
                      <div
                        style={{ marginTop: 10 }}
                        className="d-flex flex-column"
                      >
                        {filePreviewUrls.map((filePreviewUrl: any, i: any) => {
                          return (
                            <div
                              style={{ marginRight: 5, marginBottom: 5 }}
                              key={i}
                            >
                              <div
                                onClick={() => {
                                  const newData = [...filePreviewUrls];
                                  newData.splice(i, 1);
                                  setFilePreviewUrls(newData);
                                }}
                                style={{
                                  position: "absolute",
                                  background: "#fff",
                                  width: 20,
                                  height: 20,
                                  borderRadius: "50%",
                                  // top: -10,
                                  // left: -10,
                                }}
                                className="d-flex justify-content-center align-items-center"
                              >
                                X
                              </div>
                              {isImage[i] ? (
                                <img
                                  src={filePreviewUrl as string}
                                  alt="Preview"
                                  style={{
                                    width: 50,
                                    height: 70,
                                    borderRadius: 8,
                                  }}
                                />
                              ) : isPdf[i] ? (
                                // eslint-disable-next-line jsx-a11y/iframe-has-title
                                <p style={{ marginLeft: 25 }}>
                                  {truncateString(imageAsFile[i]?.name)}
                                </p>
                              ) : (
                                <p>File type not supported</p>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                ) : props.application?.portalApplicationStatus?.applicationPhases.filter(
                    (stage: { phaseState: string }) =>
                      stage.phaseState === "AwaitingResponseStudent"
                  )[0].status === "Confirmation" ? (
                  <>
                    <Dropdown>
                      <Dropdown.Toggle
                        className="dd-custom-toggle "
                        id="dropdown-custom-components"
                        style={{ padding: 0 }}
                      >
                        <div className="d-flex flex-row flex-wrap w-100">
                          {selectedStatus || props.application.status[0] ? (
                            <div
                              className="d-flex flex-column"
                              style={{
                                position: "relative",
                                marginRight: 12,
                              }}
                            >
                              <Badge
                                pill
                                bg={
                                  selectedStatus ||
                                  props.application.status === "Offer on KCO"
                                    ? "info"
                                    : "success"
                                }
                                style={{
                                  marginBottom: 4,
                                  cursor: "pointer",
                                }}
                              >
                                {selectedStatus === ""
                                  ? props.application.status[0]
                                  : selectedStatus}
                              </Badge>
                            </div>
                          ) : (
                            <div
                              className="form-input d-flex align-items-center w-100"
                              style={{ cursor: "pointer" }}
                            >
                              Select Status
                            </div>
                          )}
                        </div>
                      </Dropdown.Toggle>

                      {/* {showMenu === z.id ? ( */}
                      <Dropdown.Menu>
                        {["Offer on KCO", "KCO Approved", "Status 3"].map(
                          (a: any, key: any) => (
                            <Dropdown.Item>
                              {" "}
                              <Badge
                                pill
                                className="d-flex flwx-column"
                                key={key}
                                style={{ marginBottom: 10 }}
                                onClick={async () => {
                                  try {
                                    setloading(true);

                                    setselectedStatus(a);
                                    setloading(false);
                                  } catch (error: any) {
                                    setloading(false);
                                    alert(error.response.data.message);
                                  }
                                }}
                                bg={bg[key]}
                              >
                                {a}
                              </Badge>
                            </Dropdown.Item>
                          )
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <div>Click on Submit to go to next step</div>
                  </>
                ) : props.application?.portalApplicationStatus?.applicationPhases.filter(
                    (stage: { phaseState: string }) =>
                      stage.phaseState === "AwaitingResponseStudent"
                  )[0].status === "FG/BS" ? (
                  <>
                    <UploadInput>
                      <input
                        style={{
                          height: "100%",
                          opacity: 0,
                          width: "100%",
                          position: "absolute",
                          cursor: "pointer",
                        }}
                        type="file"
                        accept=".pdf,.jpg,.jpeg,.png"
                        onChange={(e: any) => handleImageAsFile(e)}
                      />

                      <UploadText>Upload Document</UploadText>
                      <UploadSubtext>
                        Drag and drop your file here or click to upload a local
                        file
                      </UploadSubtext>
                    </UploadInput>
                    {filePreviewUrls.length > 0 && (
                      <div
                        style={{ marginTop: 10 }}
                        className="d-flex flex-column"
                      >
                        {filePreviewUrls.map((filePreviewUrl: any, i: any) => {
                          return (
                            <div
                              style={{ marginRight: 5, marginBottom: 5 }}
                              key={i}
                            >
                              <div
                                onClick={() => {
                                  const newData = [...filePreviewUrls];
                                  newData.splice(i, 1);
                                  setFilePreviewUrls(newData);
                                }}
                                style={{
                                  position: "absolute",
                                  background: "#fff",
                                  width: 20,
                                  height: 20,
                                  borderRadius: "50%",
                                  // top: -10,
                                  // left: -10,
                                }}
                                className="d-flex justify-content-center align-items-center"
                              >
                                X
                              </div>
                              {isImage[i] ? (
                                <img
                                  src={filePreviewUrl as string}
                                  alt="Preview"
                                  style={{
                                    width: 50,
                                    height: 70,
                                    borderRadius: 8,
                                  }}
                                />
                              ) : isPdf[i] ? (
                                // eslint-disable-next-line jsx-a11y/iframe-has-title
                                <p style={{ marginLeft: 25 }}>
                                  {truncateString(imageAsFile[i]?.name)}
                                </p>
                              ) : (
                                <p>File type not supported</p>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                ) : props.application?.portalApplicationStatus?.applicationPhases.filter(
                    (stage: { phaseState: string }) =>
                      stage.phaseState === "AwaitingResponseStudent"
                  )[0].status === "CAS Received" ? (
                  <>
                    <UploadInput>
                      <input
                        style={{
                          height: "100%",
                          opacity: 0,
                          width: "100%",
                          position: "absolute",
                          cursor: "pointer",
                        }}
                        type="file"
                        accept=".pdf,.jpg,.jpeg,.png"
                        onChange={(e: any) => handleImageAsFile(e)}
                      />

                      <UploadText>Upload Document</UploadText>
                      <UploadSubtext>
                        Drag and drop your file here or click to upload a local
                        file
                      </UploadSubtext>
                    </UploadInput>
                    {filePreviewUrls.length > 0 && (
                      <div
                        style={{ marginTop: 10 }}
                        className="d-flex flex-column"
                      >
                        {filePreviewUrls.map((filePreviewUrl: any, i: any) => {
                          return (
                            <div
                              style={{ marginRight: 5, marginBottom: 5 }}
                              key={i}
                            >
                              <div
                                onClick={() => {
                                  const newData = [...filePreviewUrls];
                                  newData.splice(i, 1);
                                  setFilePreviewUrls(newData);
                                }}
                                style={{
                                  position: "absolute",
                                  background: "#fff",
                                  width: 20,
                                  height: 20,
                                  borderRadius: "50%",
                                  // top: -10,
                                  // left: -10,
                                }}
                                className="d-flex justify-content-center align-items-center"
                              >
                                X
                              </div>
                              {isImage[i] ? (
                                <img
                                  src={filePreviewUrl as string}
                                  alt="Preview"
                                  style={{
                                    width: 50,
                                    height: 70,
                                    borderRadius: 8,
                                  }}
                                />
                              ) : isPdf[i] ? (
                                // eslint-disable-next-line jsx-a11y/iframe-has-title
                                <p style={{ marginLeft: 25 }}>
                                  {truncateString(imageAsFile[i]?.name)}
                                </p>
                              ) : (
                                <p>File type not supported</p>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                ) : null}
              </Modal.Body>
              <Modal.Footer className="d-flex flex-row-reverse flex-start w-100">
                <ButtonComponent
                  onClick={() => {
                    props.handleClose();
                  }}
                  btnType="light"
                  loading={false}
                  label="Close"
                  style={{ width: "max-content", marginLeft: 10 }}
                />
                <ButtonComponent
                  btnType="dark"
                  loading={loading || data.uploading}
                  disabled={loading || data.uploading}
                  label="Submit"
                  style={{ width: "max-content" }}
                />
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};
