import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  createComment,
  deleteComments,
  getUsers,
} from "../../Services/Function";
import { Form, Formik, FormikHelpers } from "formik";
import { ButtonComponent } from "../ButtonComponent";
import { useSelector } from "react-redux";
import moment from "moment";
import { MentionsInput, Mention } from "react-mentions";
import Picker from "@emoji-mart/react";
import { DeleteIcon } from "../../Assets";
import { DeleteModal } from "../DeleteModal";

// Define types for User, Comment, Reaction, and Props
type User = {
  id: string;
  name: string;
  slackMemberId: string;
};

type Comment = {
  id: string;
  content: string;
  createdBy: string;
  dateTime: string;
  reactions: Reaction[];
  userId: any;
};

type Reaction = {
  id: string;
  emoji: string;
};

type Props = {
  show?: boolean;
  handleClose: () => void;
  comments: Comment[];
  selectedStudent: { id: string };
  getComments: (studentId: string) => void;
};

// Define HighlightedString as it's used in the component
const HighlightedString: React.FC<{ text: string }> = ({ text }) => {
  const highlightedText = text.replace(
    /@(\w+)/g,
    '<span style="color: rgb(127, 86, 217);font-size:14px;background-color: rgb(74 0 237 / 23%);padding: 2px; border-radius: 4px;">@$1</span>'
  );

  return (
    <div
      style={{ fontSize: 14 }}
      dangerouslySetInnerHTML={{ __html: highlightedText }}
    />
  );
};

export const CommentsModal: React.FC<Props> = (props) => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<string>("");
  const [commentSeleted, setcommentSeleted] = useState<any>({});
  const [deleteModal, setdeleteModal] = useState(false);
  const [mentions, setMentions] = useState<
    Array<{ id: string; display: string; memberId: string }>
  >([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false); // State to show/hide emoji picker

  const auth = useSelector((state: any) => state.auth);

  useEffect(() => {
    async function fetchData() {
      const res = await getUsers();
      setUsers(res.results);
    }
    fetchData();
  }, []);

  const usersForMentions = users.map((user) => ({
    id: user.id,
    display: user.name,
    memberId: user.slackMemberId,
  }));

  const handleReaction = (commentId: string, emoji: string) => {
    const updatedComments = props.comments.map((comment) =>
      comment.id === commentId
        ? {
            ...comment,
            reactions: [
              ...comment.reactions,
              { id: Date.now().toString(), emoji },
            ],
          }
        : comment
    );
    // Assuming setComments or similar would be used in a real scenario.
  };

  const addEmoji = (emoji: any) => {
    setCommentText((prevText) => prevText + emoji.native); // Add emoji to comment text
    setShowEmojiPicker(false); // Close the picker after selecting an emoji
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      id="modal-right"
      className="modal fade custom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="modal-title">Comments</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: "scroll", backgroundColor: "#f7f7f7" }}>
        <ul className="comment-list">
          {props.comments
            .slice()
            .reverse()
            .map((comment: Comment) => (
              <li key={comment.id} className="comment-item">
                <div className="comment-header d-flex justify-content-between">
                  <div className="user-info">
                    <span className="user-name">{comment.createdBy}</span>
                    <span className="comment-date">
                      {moment(comment.dateTime).format("DD/MM/YYYY-hh:mm:a")}
                    </span>
                  </div>
                  {auth.userDetails.id === comment.userId ? (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setcommentSeleted(comment);
                        setdeleteModal(true);
                      }}
                    >
                      <DeleteIcon />
                    </div>
                  ) : null}
                </div>

                <HighlightedString text={comment.content} />

                <div className="reaction-icons">
                  {comment.reactions &&
                    comment.reactions.map((reaction: Reaction) => (
                      <span key={reaction.id}>{reaction.emoji}</span>
                    ))}
                </div>
              </li>
            ))}
        </ul>
      </Modal.Body>

      <Modal.Footer>
        <Formik
          initialValues={{ comment: "" }}
          onSubmit={async (
            values: any,
            { setSubmitting }: FormikHelpers<any>
          ) => {
            try {
              setLoading(true);

              await createComment({
                studentId: props.selectedStudent.id,
                content: commentText.replace(/@\[([^)]+)\]\([^)]+\)/g, "@$1"),
                dateTime: new Date(),
                createdBy: auth.userDetails.name,
                userId: auth.userDetails.id,
                mentions: mentions,
              });

              props.getComments(props.selectedStudent.id);
              setCommentText("");
              setMentions([]);
              setLoading(false);
            } catch (error) {
              setLoading(false);
            }
          }}
        >
          <Form style={{ width: "70%" }} className="d-flex w-100 flex-column">
            <div className="d-flex align-items-center position-relative">
              <MentionsInput
                id={"commentText"}
                className="textarea-input w-100"
                name={"commentText"}
                forceSuggestionsAboveCursor={true}
                value={commentText}
                onChange={(
                  e: any,
                  _newValue,
                  _newPlainTextValue,
                  mentions: any
                ) => {
                  setCommentText(e.target.value);
                }}
              >
                <Mention
                  trigger="@"
                  data={usersForMentions}
                  appendSpaceOnAdd={true}
                  renderSuggestion={(
                    suggestion,
                    search,
                    highlightedDisplay
                  ) => <div className="user-mention">{highlightedDisplay}</div>}
                  onAdd={(id: string | number, display: string) => {
                    const matchedUser = usersForMentions.find(
                      (user) => user.id === id
                    );

                    if (matchedUser) {
                      setMentions((prevMentions) => [
                        ...prevMentions.filter(
                          (mention) => mention.display !== matchedUser.display
                        ),
                        {
                          display: matchedUser.display,
                          id: matchedUser.id,
                          memberId: matchedUser.memberId,
                        },
                      ]);
                    }
                  }}
                  displayTransform={(_id: any, display: any) => `@${display}`}
                />
              </MentionsInput>
              <div
                className="emoji-picker-icon"
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                style={{ marginLeft: "10px", cursor: "pointer", fontSize: 20 }}
              >
                😀
              </div>

              {/* Emoji picker positioned directly below the input */}
              {showEmojiPicker && (
                <div
                  style={{ position: "absolute", bottom: "60px", right: "0" }}
                >
                  <Picker
                    onEmojiSelect={addEmoji}
                    emojiTooltip={true}
                    title="Pick an emoji"
                  />
                </div>
              )}
            </div>

            <ButtonComponent
              btnType="dark"
              loading={loading}
              disabled={loading || !commentText}
              label="Send"
            />
          </Form>
        </Formik>
      </Modal.Footer>
      {deleteModal ? (
        <DeleteModal
          onHide={() => setdeleteModal(false)}
          onClick={async () => {
            setLoading(true);
            await deleteComments(commentSeleted.id);
            props.getComments(props.selectedStudent.id);
            setCommentText("");
            setdeleteModal(false);
            setMentions([]);
            setLoading(false);
          }}
          show={deleteModal}
        />
      ) : null}
    </Modal>
  );
};
