/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Modal } from "react-bootstrap";

type Props = {
  pdf: any;
  show: boolean;
  images: any;
  handleClose: any;
  openViewer: any;
};

export const ViewerModal = (props: Props) => {
  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
        fullscreen
        className="modal fade doc-viewer-modal"
      >
        <Modal.Header style={{ color: "#fff" }} closeButton></Modal.Header>
        <Modal.Body className="d-flex justify-content-center align-items-center">
          {props.pdf ? (
            <iframe
              src={props.pdf}
              title="PDF Viewer"
              width="80%"
              height="100%" // Set the desired height
            ></iframe>
          ) : (
            <div
              style={{
                backgroundImage: `url('${props.images}')`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                width: "80%",
                paddingTop: "50%",
                backgroundRepeat: "no-repeat",
                borderRadius: 8,
              }}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};
