/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { Suspense, useState } from "react";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./Components/GlobalStyles";
import { lightTheme, darkTheme } from "./Components/Theme";
import { ApplicationDetails, Header, Loader } from "./Components";
import { Route, Routes, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import {
  Courses,
  Dashboard,
  Fees,
  Leads,
  Login,
  News,
  Profile,
  StudentPage,
  Students,
  Users,
  WhatsApp,
  Notification,
} from "./pages";
import "react-circular-progressbar/dist/styles.css";
import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
// import "emoji-mart/css/emoji-mart.css"; // Emoji-mart styles
import { useSelector } from "react-redux";
import { ResetPassword } from "./pages/ResetPassword";

function App() {
  let location = useLocation();
  const auth = useSelector((state: any) => state.auth);

  const [theme, setTheme] = useState("light");
  // const themeToggler = () => {
  //   theme === "light" ? setTheme("dark") : setTheme("light");
  // };

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <GlobalStyles />
      {location.pathname === "/" ||
      location.pathname === "/reset-password" ? null : (
        <Header />
      )}
      {/* <button style={{ position: "absolute" }} onClick={themeToggler}>
        Switch Theme
      </button> */}
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/students" element={<Students />} />
          <Route path="/leads" element={<Leads />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/whatsapp" element={<WhatsApp />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/news" element={<News />} />
          {auth.userDetails.role === "63d2ec57fdc142001c0074e6" ||
          auth.userDetails.role === "63c92057dab279194bab8d8f" ? (
            <Route path="/setting" element={<Users />} />
          ) : null}
          {/* <Route
            path="/student/applicationDetails/:studentId/:id"
            element={<ApplicationDetails />}
          /> */}
          <Route
            path={
              location.pathname.includes("/applicationDetails")
                ? "/student/:page/:id/:applicationId"
                : "/student/:page/:id"
            }
            element={<StudentPage />}
          />
          <Route path="/fees" element={<Fees />} />
          <Route path="/notifications" element={<Notification />} />
          <Route path="/profile/:id" element={<Profile />} />
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
