import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonComponent } from "../ButtonComponent";
import { Modal } from "react-bootstrap";
import { UploadInput, UploadText, UploadSubtext } from "./style";
import { truncateString, updateDocument } from "../../Services/Function";
import { bindActionCreators } from "redux";
import { dataAction } from "../../state";

interface Props {
  show: boolean;
  handleClose: () => void;
  document: any;
  reloadDocuments: () => void;
}

export const UploadDocument: React.FC<Props> = (props) => {
  const [filePreviewUrls, setFilePreviewUrls] = React.useState<
    (string | ArrayBuffer)[]
  >([]);
  const [isImage, setIsImage] = React.useState<boolean[]>([]);
  const [isPdf, setIsPdf] = React.useState<boolean[]>([]);
  const [imageAsFile, setImageAsFile] = React.useState<File[]>([]);
  const [dragActive, setDragActive] = React.useState(false); // State for drag & drop

  const dispatch = useDispatch();
  const uploading = useSelector((state: any) => state.data.uploading);
  const { handleFileUpload, setUploading } = bindActionCreators(
    dataAction,
    dispatch
  );

  React.useEffect(() => {
    // setUploading(false);
    // Optionally handle side effects
  }, [dispatch]);

  const handleImageAsFile = (files: FileList | null) => {
    if (files) {
      const filesArray = Array.from(files);
      setImageAsFile(filesArray);

      filesArray.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          setFilePreviewUrls((prevFilePreviewUrls) => [
            ...prevFilePreviewUrls,
            reader.result as string,
          ]);
          setIsImage((prevIsImage) => [
            ...prevIsImage,
            file.type.includes("image"),
          ]);
          setIsPdf((prevIsPdf) => [...prevIsPdf, file.type.includes("pdf")]);
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleImageAsFile(event.target.files);
  };

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === "dragenter" || event.type === "dragover") {
      setDragActive(true);
    } else if (event.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      handleImageAsFile(event.dataTransfer.files);
      event.dataTransfer.clearData();
    }
  };

  const handleSaveDocument = async () => {
    try {
      props.handleClose();
      setUploading(props.document.id);
      const downloadURL: any = await handleFileUpload(imageAsFile);

      await updateDocument(props.document.id, {
        docState:
          props.document.blobInfo?.length || downloadURL.length
            ? "Upload"
            : "Not Uploaded",
        blobInfo: [
          ...props.document.blobInfo,
          ...downloadURL.map((a: any) => ({
            blobUrl: a.url,
            name: a.name,
          })),
        ],
      });

      props.reloadDocuments();
      setUploading(false);
    } catch (error) {
      console.error("Save document error:", error);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      size="sm"
      centered
      className="upload-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Upload Documents</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <UploadInput>
            <input
              style={{
                height: "100%",
                opacity: 0,
                width: "100%",
                position: "absolute",
                cursor: "pointer",
              }}
              type="file"
              accept=".pdf,.jpg,.jpeg,.png"
              multiple
              onChange={handleFileChange}
            />
            <UploadText>Upload Document</UploadText>
            <UploadSubtext>
              Drag and drop your file here or click to upload a local file
            </UploadSubtext>
          </UploadInput>

          {dragActive && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>Drop files here...</p>
            </div>
          )}
        </div>
        {filePreviewUrls.length > 0 && (
          <div style={{ marginTop: 10 }} className="d-flex flex-row">
            {filePreviewUrls.map((filePreviewUrl: any, i: any) => (
              <div style={{ marginRight: 5 }} key={i}>
                <div
                  onClick={() => {
                    const newData = [...filePreviewUrls];
                    newData.splice(i, 1);
                    setFilePreviewUrls(newData);
                  }}
                  style={{
                    position: "absolute",
                    background: "#fff",
                    width: 20,
                    height: 20,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  X
                </div>
                {isImage[i] ? (
                  <div
                    style={{
                      backgroundImage: `url(${filePreviewUrl})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: 70,
                      height: 70,
                      borderRadius: 8,
                    }}
                  />
                ) : isPdf[i] ? (
                  <p style={{ marginLeft: 25 }}>
                    {truncateString(imageAsFile[i]?.name)}
                  </p>
                ) : (
                  <p>File type not supported</p>
                )}
              </div>
            ))}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonComponent
          onClick={handleSaveDocument}
          btnType="dark"
          label="Save"
          loading={uploading}
          disabled={uploading}
        />
      </Modal.Footer>
    </Modal>
  );
};
