import { createGlobalStyle } from "styled-components";
export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    font-family: Tahoma, Helvetica, Arial, Roboto, sans-serif;
    transition: all 0.50s linear;
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
    .filter-dd-menu{
    width:300px;
    background-color: wh !important;
     padding: 20px !important
     }
    input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
  .delete-modal .modal-content{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
    .dashboard-tabs {
      background: transparent !important;
    }
 .dashboard-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
    background: transparent !important;
    border-bottom: 2px solid #7F56D9;
}
    .upload-modal .modal-content{
   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
.remove-filter{
    font-weight: 600 !important;
    color: red !important;}
  
.nav-tabs .nav-link{
   font-size: 16px !important;
   font-style: normal;
   font-weight: 600;
    }
  .pc-view{
    display:block;
  }
  input[type="number"] {
    -moz-appearance: textfield !important;
}
.progress, .progress-stacked{
  height:15px !important;
  width: 93%;
}
.progress-bar{
  background-color:#7F56D9 !important;
  color:#000 !important;
}
.mention-text {
  background-color:#7F56D9 !important;
}
.fees-search-bar::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      padding-left: 5px !important;
    }
      .fees-search-bar{
      padding-left: 5px !important;
      }
.search-con{
  display:flex;
  justify-content:space-between;
}
.dd-btn{
  background-color: #7F56D9 !important;
  color: #fff !important;
  border-radius:20px !important;
  width: 135px !important;
  height: 38px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  justify-content:center
}
.css-t3ipsp-control:hover{
  outline:none !important;
  border-color: #7F56D9 !important; 
  box-shadow:none !important; 
}
.header-avatar-dd{
  background-color: transparent !important;
  border:none !important;
}
.btn-outline-secondary:hover{
  background-color:rgb(127, 86, 217) !important;
  color: #fff !important;
}
.table-responsive{
  padding-bottom: 30px;
}
.status-menu {
  width:auto;
}
.header-avatar-dd::after{
display:none !important;
}
.dd-btn::after{
content:"" !important;
}
.doc-modal{
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.menu-dd{
  border:none !important;
}
.menu-dd::after{
display:none !important
}
.right-aligned-modal .modal-dialog {
  margin: 0;
  transform: translate(calc(50vw + 50%), -50%);
  width: fit-content;
  right: 0;
  top: 50%;
  bottom: auto;
  left: auto;
}
.comment-section {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.comment-list {
  list-style: none;
  padding: 0;
  overflow-y: auto; /* Enable vertical scrolling for comments */
}

.comment-item {
  margin-bottom: 20px;
  padding: 5px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
}
.user-mention{
  // background-color:rgb(127 86 217 / 63%);
  // font-weight:bold
  padding:5px
}
.textarea-input__input{

  border: 1px solid #dfe1e6 !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 6px !important;
  outline:none;
}
.comment-header {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.error{ 
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  color:red;
  margin-top: -15px;
}
.user-info {
  display: flex;
  flex-direction: row;
  align-items:center
}
.chart-container {
  height: 400px !important; /* or any fixed height */
  max-height: 400px; /* or any appropriate max height */
}
.user-name {
  font-weight: bold;
  color: #333;
}
.loading {
  filter: blur(10px);
  clip-path: inset(0);
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed{
  padding:0;
}
.accordion-button:focus{
  background-color:#fff;
  box-shadow:none
}
.accordion-button:not(.collapsed){
  background-color:#fff;
}
.loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}
.subject-dd .dropdown-toggle::after{
  content:"" !important;
}
.comment-content {
  font-size: 14px;
  color: #333;
  margin:0px
}

.load-more-button {
  display: block;
  margin: 10px auto;
  padding: 10px 20px;
  background-color: #7F56D9;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.load-more-button:hover {
  background-color: #6242a4;
}
  .filter-dd{
      background-color: transparent !important;
    border-bottom: 1px #7f56d9 solid !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    position: relative;
    bottom: -5px;
    }
    .filter-dd::after{
    border-top: none !important;
    right: -10px;
    position: relative;
        }
.react-datepicker{
font-family:Roboto;
color:  #7F7F7F;
text-align: center;
font-size: 12px;
font-style: normal;
font-weight: 400;
}
.react-datepicker__header{
  background-color: transparent !important;
  border-bottom: 1px solid transparent !important;
}
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
      background-color: rgb(108, 99, 255);
          color: #fff !important;


  }
      .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: #7F7F7F;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}
     .react-datepicker {
  border-color:#EBEBEB;
      }
      .filter-calendar{
      border-left: none;
    border-top: none;
    border-bottom: none;
    border-radius: 0;
      }
     .filter-calendar2{
      border: none;}
.comment-input {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}
.comment-date {
  color: #888;
  font-size:10px;
  margin-left:6px;
}
.input-field {
  flex: 1;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  resize: none;
  background-color: #f7f7f7;
  color: #333;
  font-size: 16px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #7F56D9;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #6242a4;
}

.comment-textarea{
  width:100% !important;
}
  .add-phone-btn{
    background:#7F56D9;
    color:#fff;
    border:none !important;
    width:max-content;
    border-radius:8px;
    height:38px;
    padding:0 10px;
  }
  .mobile-view{
    display:none;
  }
  .bg-light{
    background-color: #7F56D9 !important;
    color:#fff;
  }
  .navbar-toggler{
    border-color:#fff ;
  }
  .nav-link{
    color:#fff !important;
  }
  .nav-tabs .nav-link{
    color: #7a869a !important;

  }
  .image-gallery-content .image-gallery-slide .image-gallery-image{
    max-height:500px;
  }
  .doc-viewer-modal  .modal-content .modal-header .btn-close{
    opacity:1;
    background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;

  }
  .doc-viewer-modal  .modal-content{
    max-height:100vh;
    background-color:transparent;

  }
  .doc-viewer-modal  .modal-content .modal-body{
   overflow:scroll;
  }
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
      display: block;
      width: 100% !important;
      margin-right:5px;
      margin-bottom:0 !important;
  }
  .react-datepicker__input-container input{
    height: 42px;
    width:100%;
    background: #ffffff;
    font-family: "Inter";
    border: 1px solid #dfe1e6;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 9px 13px;
    margin-bottom: 24px;
    width: 100%;
    &:hover {
      border: 1px solid #6941c6;
      outline: none;
    }
    &:focus {
      border: 1px solid #6941c6;
      outline: none;
    }
    ::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #667085;
    }
  }
    
  @media (max-width: 992px) {
    .form-input {
      width: 100% !important;
    }
.filter-dd-menu{
    width:100%;
    background-color: #fff !important;
     padding: 20px !important
     }
      .res-mb-4{
          margin-bottom: 1.5rem !important;

      }
    .progress, .progress-stacked{
      height:15px !important;
      width: 100%;
              margin: 20px 0;
    }
    .search-con{
      display:block;
    }
    .res-margintop-15{
      margin-top:15px;
    }
    .doc-viewer-modal  .modal-content{
      max-height:100%;
    }
  }
  @media(max-width: 992px){
    .pc-view{
      display:none;
    }
    .mobile-view{
      display:block;
    }
  }


  .fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

@media (max-width: 768px) {
  th, td {
    font-size: 14px;
    padding: 8px;
  }

  .d-flex {
    display: flex;
  }

  .flex-column {
    flex-direction: column;
  }

  .align-items-center {
    align-items: center;
  }

  .justify-content-around {
    justify-content: space-around;
  }
}

  `;
