/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import {
  AppliedBadge,
  CourseCard,
  CourseHeader,
  CourseText,
  ImgCon,
  CourseName,
  InstituteName,
  BorderBottom,
  SubHeader,
  ReadMore,
} from "./styled/course";
import { Col, Container, Row, Dropdown, Form } from "react-bootstrap";
import {
  ButtonComponent,
  DropdownComponent,
  Loader,
  PaginationComponent,
  UniversityDDComponent,
  MultipleInputComponent,
  AddCourse,
  StartApplication,
  CountryInputComponent,
} from "../Components";
import {
  getApplicationById,
  getApplications,
  getApplicationsById,
  getCourseBycourseRefId,
  getCourseLevel,
  getCourses,
  getStudents,
  searchStudents,
  searchSubjects,
  searchUniversities,
  updateStudent,
} from "../Services/Function";
import { ShortList } from "../Assets";
import { debounce } from "debounce";
import { useState } from "react";

interface ICoursesProps {}

export const Courses: React.FunctionComponent<ICoursesProps> = (props) => {
  const auth = useSelector((state: any) => state.auth);
  var authToken = localStorage.getItem("authToken");
  const [courses, setcourses] = React.useState<any>([]);
  const [courselevel, setcourselevel] = React.useState<any>("");
  const [searchText, setsearchText] = React.useState("");
  const [searchText1, setsearchText1] = React.useState("");

  const [totalPage, settotalPage] = React.useState(0);
  const [page, setpage] = React.useState<any>(1);
  const [selectedSubject, setselectedSubject] = React.useState([]);
  const [subjects, setsubjects] = React.useState<any>([]);
  const [city, setcity] = React.useState("");
  const [totalCourses, settotalCourses] = React.useState(0);
  const [attendanceTypes, setattendanceTypes] = React.useState<any>("");
  const [showFullSummary, setShowFullSummary] = React.useState(false);
  const [showFullOverview, setshowFullOverview] = React.useState(false);

  const [searchInstituteText, setsearchInstituteText] = React.useState<any>("");
  const [shortlistedCourses, setshortlistedCourses] = React.useState<any>([]);
  const [selectedUni, setselectedUni] = React.useState([]);
  const [courseDetail, setcourseDetail] = React.useState<any>({});
  const [students, setstudents] = React.useState<any>([]);
  const [selectedStudent, setselectedStudent] = React.useState<any>({});
  const [modalShow, setmodalShow] = React.useState<any>(false);
  const [selectedCourse, setselectedCourse] = React.useState<any>([]);
  const [applications, setapplications] = React.useState<any>([]);
  const [modeOfStudy, setmodeOfStudy] = React.useState<any>("Full Time");
  const [selectedCity, setselectedCity] = React.useState("");
  const [courseDuration, setcourseDuration] = React.useState<any>("");
  const [errorMessage, seterrorMessage] = React.useState("");
  const [loading, setloading] = React.useState(false);
  const [universities, setuniversities] = useState([]);
  const [searchUniText, setsearchUniText] = useState("");
  const [addCourse, setaddCourse] = useState(false);
  const [selectedUniversity, setselectedUniversity] = useState("");
  const [courseLevels, setcourseLevels] = useState([]);

  function decodeHtmlEntities(str: string): string {
    const txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
  }

  function convertStringtoHtml(
    html: string,
    elementId: string,
    showFullSummary: boolean
  ): any {
    if (!html) {
      return ""; // or return a default value
    }

    // Decode HTML entities
    const decodedHtml = decodeHtmlEntities(html);

    const div = document.createElement("div");
    const summaryText = showFullSummary
      ? decodedHtml
      : decodedHtml.slice(0, 200) + "...";
    div.innerHTML = summaryText;
    const resultElement = div.firstElementChild;
    const targetElement = document.getElementById(elementId);

    if (targetElement) {
      targetElement.innerHTML = "";

      // Check if resultElement is not null before appending
      if (resultElement) {
        targetElement.appendChild(resultElement);
      } else {
        console.error(
          `Converted HTML doesn't contain a valid element: ${summaryText}`
        );
        targetElement.innerHTML = summaryText; // Fall back to setting the innerHTML directly
      }
    } else {
      console.error(`Element with ID "${elementId}" not found`);
    }
  }

  React.useEffect(() => {
    async function fetchData() {
      setloading(true);

      const courseLevels = await getCourseLevel({
        limit: 10,
        page: 1,
      });
      setcourseLevels(courseLevels.results);

      const students = await getStudents({
        sortBy: "createdAt:desc",
        limit: 10,
        page: 1,
      });

      const studentExists = students.results.length > 0;

      const selectedSubject =
        studentExists && students.results[0]?.preference?.courseSubjectIds
          ? students.results[0].preference.courseSubjectIds
          : [];

      const crslvl = courseLevels.results
        .filter(
          (z: any) => z.id === students.results[0]?.preference?.courseLevel
        )
        .map((a: any) => ({
          name: a.en_name,
          value:
            a.en_name === "Bachelor's"
              ? "Undergraduate"
              : a.en_name === "Master's"
                ? "Postgraduate"
                : a.en_name === "Phd"
                  ? "Doctorate"
                  : "Foundation",
        }))[0];
      setselectedStudent(studentExists ? students.results[0] : null);
      setselectedSubject(selectedSubject);

      setcourselevel(crslvl);

      const courses = await getCourses({
        subject:
          students.results[0]?.preference?.courseSubjectIds[0]?.slug || "",
        attendanceTypes: attendanceTypes.replaceAll(" ", ""),
        courseLevel: crslvl ? crslvl.value : "",
        institution: selectedUni,
        city: city,
        placementAvailable: "",
        courseDurationValues: "",
        intakeMonths: "",
      });

      if (courses.results.length) {
        const courseDetail = await getCourseBycourseRefId(
          courses.results[0].courseRefId
        );

        setselectedCourse(courses.results[0]);
        setshortlistedCourses(
          students.results.length ? students?.results[0].shortlistedCourses : []
        );
        convertStringtoHtml(
          courseDetail[0]?.courseSummary !== ""
            ? courseDetail[0]?.courseSummary
            : "<p>No summary added</p>",
          "courseSummary",
          showFullSummary
        );

        convertStringtoHtml(
          courseDetail[0]?.institution?.overview !== ""
            ? courseDetail[0]?.institution?.overview
            : "<p>No overview added</p>",
          "overview",
          showFullSummary
        );
        setcourseDetail(courseDetail[0]);
        setcourses(courses.results);
        settotalCourses(courses.totalResults);
        settotalPage(courses.totalPages);
      }

      if (students.results.length) {
        const app = await getApplicationsById(students?.results[0].id);

        setapplications(app.data);
      }
      setloading(false);
    }

    fetchData();
  }, []);

  const reoadStudents = async () => {
    setloading(true);
    const students = await getStudents({
      sortBy: "createdAt:desc",
      limit: 10,
      page: 1,
    });
    const app = await getApplicationsById(selectedStudent?.id);
    setapplications(app.data);
    setselectedStudent(
      students.results.filter((z: any) => z.id === selectedStudent.id)[0]
    );

    setloading(false);
  };

  const reloadCourses = async () => {
    setloading(true);
    const courses = await getCourses({
      subject: selectedStudent?.preference?.courseSubjectIds[0]?.slug || "",
      attendanceTypes: attendanceTypes.replaceAll(" ", ""),
      courseLevel: courselevel || "",
      institution: selectedUni,
      city: city,
      placementAvailable: "",
      courseDurationValues: "",
      intakeMonths: "",
    });

    if (courses.results.length) {
      const courseDetail = await getCourseBycourseRefId(
        courses.results[0].courseRefId
      );
      setselectedCourse(courses.results[0]);
      setshortlistedCourses(selectedStudent.shortlistedCourses);
      convertStringtoHtml(
        courseDetail[0]?.courseSummary,
        "courseSummary",
        showFullSummary
      );
      convertStringtoHtml(
        courseDetail[0]?.institution?.overview,
        "overview",
        showFullSummary
      );
      setcourseDetail(courseDetail[0]);
      setcourses(courses.results);
      settotalCourses(courses.totalResults);
      settotalPage(courses.totalPages);
    }
    setloading(false);
  };
  const onClickCourseCard = async (data: any) => {
    setselectedCourse(data);
    const courseDetail = await getCourseBycourseRefId(data.courseRefId);

    convertStringtoHtml(
      courseDetail[0]?.courseSummary !== ""
        ? courseDetail[0]?.courseSummary
        : "<p>No summary added</p>",
      "courseSummary",
      showFullSummary
    );

    convertStringtoHtml(
      courseDetail[0]?.institution?.overview !== ""
        ? courseDetail[0]?.institution?.overview
        : "<p>No overview added</p>",
      "overview",
      showFullOverview
    );
    setcourseDetail(courseDetail[0]);
  };

  const onStudentClick = async (students: any) => {
    setloading(true);

    const csrLvl: any = courseLevels
      .filter((z: any) => z.id === students?.preference?.courseLevel)
      .map((a: any) => ({
        name: a.en_name,
        value:
          a.en_name === "Bachelor's"
            ? "Undergraduate"
            : a.en_name === "Master's"
              ? "Postgraduate"
              : a.en_name === "Phd"
                ? "Doctorate"
                : "Foundation",
      }))[0];

    setcourselevel(csrLvl);
    const courses = await getCourses({
      subject: students?.preference?.courseSubjectIds[0]?.slug || "",
      attendanceTypes: attendanceTypes.replaceAll(" ", ""),
      courseLevel: csrLvl
        ? csrLvl.en_name === "Bachelor's"
          ? "Undergraduate"
          : csrLvl.en_name === "Master's"
            ? "Postgraduate"
            : csrLvl.en_name === "Phd"
              ? "Doctorate"
              : "Foundation"
        : "",
      institution: selectedUni,
      city: city, //
      placementAvailable: "",
      courseDurationValues: "",
      intakeMonths: "",
    });
    setselectedCourse(courses.results[0]);

    const courseDetail = await getCourseBycourseRefId(
      courses.results[0].courseRefId
    );
    const app = await getApplicationsById(students?.id);
    setapplications(app.data);
    setshortlistedCourses(students ? students?.shortlistedCourses : []);
    setselectedSubject(students.preference?.courseSubjectIds || []);
    convertStringtoHtml(
      courseDetail[0]?.courseSummary,
      "courseSummary",
      showFullSummary
    );
    convertStringtoHtml(
      courseDetail[0]?.institution?.overview,
      "overview",
      showFullSummary
    );
    setcourseDetail(courseDetail[0]);
    setcourses(courses.results);
    settotalCourses(courses.totalResults);
    settotalPage(courses.totalPages);
    setloading(false);
  };

  const shortlistCourse = (data: any) => {
    if (shortlistedCourses.includes(data.id)) {
      updateStudent(selectedStudent.id, {
        shortlistedCourses: shortlistedCourses.filter(
          (z: string) => !z.includes(data.id)
        ),
      });
      setshortlistedCourses(
        shortlistedCourses.filter((z: string) => !z.includes(data.id))
      );
    } else {
      shortlistedCourses.push(data.id);
      updateStudent(selectedStudent.id, {
        shortlistedCourses: shortlistedCourses,
      });
    }
  };

  if (!auth.isLoggedIn && !authToken) {
    return <Navigate to="/" />;
  }

  const commonElements: any = courses.filter((element: any) => {
    return applications.some((obj: any) => obj.course === element.id);
  });

  const filterCourses = async (
    subject: any,
    attendanceType: any,
    courseLevel: any,
    selectedUni: any,
    city: any,
    courseDurationValues: any
  ) => {
    const selSub = subject.length
      ? subject?.map((item: any) => item.slug).join(",")
      : "";

    const selUni = selectedUni.length
      ? selectedUni?.map((item: any) => item.slug).join(",")
      : "";
    const data = courseLevel;
    const courses = await getCourses({
      subject: selSub,
      attendanceTypes: attendanceType.replaceAll(" ", ""),
      courseLevel: data,
      institution: selUni,
      city: city,
      placementAvailable: "",
      courseDurationValues: courseDurationValues,
      intakeMonths: "",
      page: 1,
    });
    setpage(1);

    setcourses(courses.results);
    settotalCourses(courses.totalResults);
    settotalPage(courses.totalPages);
    if (courses.results.length) {
      onClickCourseCard(courses.results[0]);
    }
  };
  const searchSubect = debounce(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value) {
        setsubjects([]);
      } else {
        const res = await searchSubjects({
          text: e.target.value,
        });
        setsubjects(res?.results);
      }
    },
    500
  );

  const searchUniversity = debounce(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.value) {
        setuniversities([]);
      } else {
        const res = await searchUniversities({
          text: e.target.value,
        });
        setuniversities(res?.results);
      }
    },
    500
  );

  const searchStudent = debounce(async (e: any) => {
    if (!e.target.value) {
      setstudents([]);
    } else {
      const res = await searchStudents(
        { text: e.target.value },
        {
          stage: "",
          sortBy: "createdAt:desc",
          limit: 5,
          page: 1,
        }
      );
      setstudents(res?.results);
    }
  }, 500);

  console.log(courseDetail?.institution);

  return (
    <Container style={{ marginTop: 60 }}>
      {loading ? (
        <>
          {" "}
          <Loader width={"300px"} height={"300px"} />
        </>
      ) : (
        <>
          {" "}
          <div className="d-flex justify-content-between w-100">
            <Col style={{ paddingRight: 15 }} md={3}>
              <CountryInputComponent
                selectedCountry={selectedStudent}
                autoComplete="off"
                setsearchText={setsearchText1}
                setselectedSubject={setselectedSubject}
                filterCourses={async (sub: any) => {
                  await filterCourses(
                    "",
                    modeOfStudy,
                    courselevel.value || "",
                    selectedUni,
                    selectedCity,
                    courseDuration
                  );
                }}
                onStudentClick={(students: any) => onStudentClick(students)}
                setselectedCountry={setselectedStudent}
                student={true}
                searchCountry={searchStudent}
                countries={students}
                setcountries={setstudents}
                searchText={searchText1}
                arabic={false}
                placeholder={""}
              />
            </Col>

            <ButtonComponent
              label={"Add Course"}
              loading={false}
              onClick={() => setaddCourse(true)}
              btnType={"dark"}
              style={{ marginBottom: 10 }}
            />
          </div>
          <Row>
            <Col md={3} style={{ marginBottom: 10 }}>
              <UniversityDDComponent
                title="Subjects"
                style={{ zIndex: 1 }}
                ddClass="subject-dd"
                // ifSelected={selectedSubject.length}
                subjectCount={selectedSubject.length}
                element={
                  <div style={{ paddingTop: 15 }}>
                    <MultipleInputComponent
                      selectedUniversity={selectedSubject}
                      setselectedUniversity={setselectedSubject}
                      setsearchText={setsearchText}
                      arabic={false}
                      coursePage={false}
                      autoComplete={"off"}
                      // conStyle={{ flexDirection: "column" }}
                      filterCourses={async (sub: any) => {
                        await filterCourses(
                          sub,
                          modeOfStudy,
                          courselevel.value || "",
                          selectedUni,
                          selectedCity,
                          courseDuration
                        );
                      }}
                      multiSelectClass="subject-multiselect-input"
                      className="subject-input"
                      universities={subjects}
                      searchText={searchText}
                      searchUniversity={(e: any) => searchSubect(e)}
                      setuniversities={setsubjects}
                      seterrorMessage={seterrorMessage}
                      error={
                        errorMessage ? (
                          <div style={{ marginBottom: 5 }}>{errorMessage}</div>
                        ) : null
                      }
                    />
                  </div>
                }
                data={[]}
                onClick={async (data: React.SetStateAction<string>) => {}}
                selected={"Subjects"}
              />
            </Col>

            <Col md={3}>
              <Dropdown style={{ marginBottom: 10 }}>
                <Dropdown.Toggle>
                  {courselevel.name || "Select"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {[
                    { name: "Foundation", value: "Foundation" },
                    { name: "Bachelor's", value: "Undergraduate" },
                    { name: "Master's", value: "Postgraduate" },
                    { name: "Phd", value: "Doctorate" },
                  ].map((a: any, key: any) => (
                    <Dropdown.Item
                      key={key}
                      onClick={async () => {
                        setcourselevel(a);
                        await filterCourses(
                          selectedSubject,
                          modeOfStudy,
                          a.value || "",
                          selectedUni,
                          selectedCity,
                          courseDuration
                        );
                      }}
                      eventKey="1"
                    >
                      {a.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col md={3}>
              <Dropdown style={{ marginBottom: 10 }}>
                <Dropdown.Toggle>
                  {modeOfStudy.replace(/([A-Z])/g, " $1").trim()}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {[{ name: "FullTime" }, { name: "DistanceLearning" }].map(
                    (a: any, key: any) => (
                      <Dropdown.Item
                        key={key}
                        onClick={async () => {
                          setmodeOfStudy(a.name);
                          await filterCourses(
                            selectedSubject,
                            a.name,
                            courselevel.value || "",
                            selectedUni,
                            selectedCity,
                            courseDuration
                          );
                        }}
                        eventKey="1"
                      >
                        {a.name}
                      </Dropdown.Item>
                    )
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col md={3} style={{ marginBottom: 10 }}>
              <UniversityDDComponent
                title="University"
                style={{ zIndex: 1 }}
                ddClass="subject-dd"
                // ifSelected={selectedSubject.length}
                subjectCount={selectedUni.length}
                element={
                  <div style={{ paddingTop: 15 }}>
                    <MultipleInputComponent
                      selectedUniversity={selectedUni}
                      setselectedUniversity={setselectedUni}
                      setsearchText={setsearchUniText}
                      autoComplete={"off"}
                      arabic={false}
                      coursePage={false}
                      // conStyle={{ flexDirection: "column" }}
                      filterCourses={async (sub: any) => {
                        await filterCourses(
                          selectedSubject,
                          modeOfStudy,
                          courselevel.value || "",
                          sub,
                          selectedCity,
                          courseDuration
                        );
                      }}
                      multiSelectClass="subject-multiselect-input"
                      className="subject-input"
                      universities={universities}
                      searchText={searchUniText}
                      searchUniversity={(e: any) => searchUniversity(e)}
                      setuniversities={setuniversities}
                      seterrorMessage={seterrorMessage}
                      error={
                        errorMessage ? (
                          <div style={{ marginBottom: 5 }}>{errorMessage}</div>
                        ) : null
                      }
                    />
                  </div>
                }
                data={[]}
                onClick={async (data: React.SetStateAction<string>) => {}}
                selected={"Subjects"}
              />
            </Col>
            {loading ? (
              <Loader width={"300px"} height={"300px"} />
            ) : !courses.length ? (
              "No Courses"
            ) : (
              <>
                {" "}
                <Col md={4}>
                  {courses.map((a: any) => (
                    <CourseCard
                      onClick={() => {
                        onClickCourseCard(a);
                      }}
                      selected={selectedCourse.id === a.id}
                    >
                      <div className="d-flex justify-content-between w-100">
                        <div className="d-flex flex-column">
                          <CourseHeader>{a.name}</CourseHeader>
                          <CourseText>
                            {a.courseLevel} •{" "}
                            {a.attendanceTypes[0].replace(
                              /([a-z])([A-Z])/g,
                              "$1 $2"
                            )}{" "}
                            •{" "}
                            {a?.courseDurationValues[0] === 360
                              ? "1 Year"
                              : a?.courseDurationValues[0] === 720
                                ? "2 Years"
                                : a?.courseDurationValues[0] === 1080
                                  ? "3 Years"
                                  : "4 Years"}
                          </CourseText>
                        </div>
                        <div
                          onClick={() => {
                            shortlistCourse(a);
                          }}
                        >
                          {selectedStudent ? (
                            <ShortList
                              selected={shortlistedCourses.includes(a?.id)}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between w-100">
                        <div className="d-flex flex-column">
                          {a.approxAnnualFee === 0 ? (
                            "N/A"
                          ) : (
                            <CourseHeader>
                              <b>
                                {a.currency === "GBP" ? "£" : "$"}
                                {a.approxAnnualFee}
                              </b>{" "}
                              first year
                            </CourseHeader>
                          )}
                          <CourseText>{a.institution}</CourseText>
                        </div>
                        {commonElements.map((z: any) => {
                          if (z.id === a.id) {
                            return <AppliedBadge>Applied</AppliedBadge>;
                          }
                        })}
                      </div>
                    </CourseCard>
                  ))}
                  {courses?.length ? (
                    <PaginationComponent
                      page={parseInt(page)}
                      totalPage={totalPage}
                      onPageClick={async (x: any) => {
                        setpage(parseInt(x));

                        setsearchText("");
                        const selSub = selectedSubject
                          ?.map((item: any) => item.slug)
                          .join(",");

                        const selUni = selectedUni
                          ?.map((item: any) => item.slug)
                          .join(",");

                        const courses = await getCourses({
                          subject: selSub,
                          attendanceTypes: attendanceTypes.replaceAll(" ", ""),
                          courseLevel: courselevel.value || "",
                          institution: selUni,
                          city: city,
                          placementAvailable: "",
                          courseDurationValues: "",
                          intakeMonths: "",
                          limit: 10,
                          page: x,
                        });

                        setcourses(courses.results);
                        settotalCourses(courses.totalResults);
                        settotalPage(courses.totalPages);
                      }}
                      onChange={async () => {
                        setpage(parseInt(page) + 1);
                        if (courses?.length === 10) {
                          const selSub = selectedSubject
                            ?.map((item: any) => item.slug)
                            .join(",");
                          const selUni = selectedUni
                            ?.map((item: any) => item.slug)
                            .join(",");
                          const data = await getCourses({
                            subject: selSub,
                            attendanceTypes: attendanceTypes.replaceAll(
                              " ",
                              ""
                            ),
                            courseLevel: courselevel.value || "",
                            institution: selUni,
                            city: city,
                            placementAvailable: "",
                            courseDurationValues: "",
                            intakeMonths: "",
                            limit: 10,
                            page: page + 1,
                          });
                          setcourses(data.results);
                          settotalCourses(data.totalResults);
                          settotalPage(data.totalPages);
                        }
                      }}
                      onChangePrev={async () => {
                        setpage(parseInt(page) > 1 ? parseInt(page) - 1 : 1);
                        const selSub = selectedSubject
                          ?.map((item: any) => item.slug)
                          .join(",");
                        const selUni = selectedUni
                          ?.map((item: any) => item.slug)
                          .join(",");
                        const data = await getCourses({
                          subject: selSub,
                          attendanceTypes: attendanceTypes.replaceAll(" ", ""),
                          courseLevel: courselevel.value || "",
                          institution: selUni,
                          city: city,
                          placementAvailable: "",
                          courseDurationValues: "",
                          intakeMonths: "",
                          limit: 10,
                          page: page - 1,
                        });
                        setcourses(data.results);
                        settotalPage(data.totalPages);
                        settotalPage(data.totalPages);
                      }}
                    />
                  ) : null}
                </Col>
                <Col md={8}>
                  <div>
                    <ImgCon
                      style={{
                        background: `url(https://storage.googleapis.com/uapply/${courseDetail?.institution?.bannerUrl})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                    <div
                      className="d-flex flex-row align-items-center"
                      style={{ marginLeft: "10%" }}
                    >
                      <img
                        style={{
                          width: 106,
                          height: 106,
                          marginTop: -90,
                          // position: "absolute",
                          background: "#FFFFFF",
                          border: " 0.66736px solid #E0E6ED",
                        }}
                        alt="img"
                        className={`image${
                          !Object.keys(courseDetail).length
                            ? " loading"
                            : " loaded"
                        }`}
                        src={`https://storage.googleapis.com/uapply/${courseDetail?.institution?.logoUrl}`}
                      />
                      <div
                        className="d-flex flex-column"
                        style={{ marginLeft: 30, marginTop: 30 }}
                      >
                        <CourseName>{courseDetail?.name}</CourseName>
                        <InstituteName>
                          {courseDetail?.institution?.name}
                          {courseDetail.provider
                            ? `(${courseDetail.provider})`
                            : ""}
                        </InstituteName>
                        <div
                          style={{ margin: "30px 0" }}
                          className="d-flex flex-row"
                        >
                          {selectedStudent ? (
                            <>
                              {" "}
                              <ButtonComponent
                                label={"Shortlist"}
                                loading={false}
                                btnType={"light"}
                              />{" "}
                              <ButtonComponent
                                label={"Start Application"}
                                loading={false}
                                btnType={"dark"}
                                onClick={() => {
                                  setmodalShow(true);
                                }}
                                style={{ width: "max-content", marginLeft: 8 }}
                              />{" "}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <BorderBottom />

                  <div>
                    <SubHeader>Overview</SubHeader>
                    <div id="overview">{}</div>
                    {courseDetail?.institution?.overview &&
                      courseDetail.institution?.overview.length > 200 && (
                        <ReadMore
                          onClick={() => {
                            setshowFullOverview(!showFullOverview);
                            convertStringtoHtml(
                              courseDetail?.institution?.overview,
                              "overview",
                              !showFullOverview
                            );
                          }}
                        >
                          {showFullSummary ? "Read Less" : "Read More"}
                        </ReadMore>
                      )}
                  </div>
                  <BorderBottom />
                  <div style={{ marginTop: 30 }}>
                    <SubHeader>Course Summary</SubHeader>
                    <div id="courseSummary">{}</div>
                    {courseDetail?.courseSummary &&
                      courseDetail.courseSummary.length > 200 && (
                        <ReadMore
                          onClick={() => {
                            setShowFullSummary(!showFullSummary);
                            convertStringtoHtml(
                              courseDetail?.courseSummary,
                              "courseSummary",
                              !showFullSummary
                            );
                          }}
                        >
                          {showFullSummary ? "Read Less" : "Read More"}
                        </ReadMore>
                      )}
                  </div>
                </Col>
              </>
            )}
          </Row>
        </>
      )}

      {modalShow ? (
        <StartApplication
          show={modalShow}
          courseDetail={courseDetail}
          selectedStudent={selectedStudent}
          handleClose={() => setmodalShow(false)}
          selectedCourse={selectedCourse}
          reoadStudents={reoadStudents}
          reloadCourses={reloadCourses}
        />
      ) : null}
      {addCourse ? (
        <AddCourse
          show={addCourse}
          loading={loading}
          handleClose={() => setaddCourse(false)}
          university={universities}
          searchUniversity={searchUniversity}
          reloadCourses={reloadCourses}
          setuniversity={setuniversities}
        />
      ) : null}
      <br />
      <br />
    </Container>
  );
};
