import React, { useState } from "react";
import { Form, Dropdown, DropdownButton, Row, Col } from "react-bootstrap";
import { FilterIcon, DeleteIcon } from "../Assets"; // Assume you have a RemoveFilterIcon
import { FilterBtn } from "../pages/styled/students";
import styled from "styled-components";
import { Label } from "./FormInput/styled";
import { ButtonComponent } from "./ButtonComponent";
import { getApplications } from "../Services/Function";

interface FilterProps {
  phases: string[];
  statuses: string[];
  onFilterChange: any;
  selectedAppStatus: any;
  intakeYear: any;
  intakeMonth: any;
  confrimStatus: any;
  setIntakeYear: any;
  setIntakeMonth: any;
  setselectedAppStatus: any;
  setconfrimStatus: any;
  setApplications: any;
  reloadStudents: any;
}

export const ApplicationFilter: React.FC<FilterProps> = ({
  phases,
  statuses,
  onFilterChange,
  selectedAppStatus,
  intakeYear,
  intakeMonth,
  confrimStatus,
  setIntakeYear,
  setIntakeMonth,
  setselectedAppStatus,
  setconfrimStatus,
  setApplications,
  reloadStudents,
}) => {
  const [filterApplied, setfilterApplied] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const handlePhaseChange = (phase: string) => {
    setselectedAppStatus((prevPhases: any) =>
      prevPhases.includes(phase)
        ? prevPhases.filter((p: any) => p !== phase)
        : [...prevPhases, phase]
    );
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedYear = Number(e.target.value);
    setIntakeYear(selectedYear > 0 ? selectedYear : undefined);
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setIntakeMonth(e.target.value);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setconfrimStatus(e.target.value);
  };

  const resetFilters = async () => {
    setselectedAppStatus([]);
    setIntakeYear(undefined);
    setIntakeMonth("");
    setconfrimStatus("");
    setfilterApplied(false);
    reloadStudents(); // Refresh the student data
    onFilterChange(); // Notify the parent about the filter reset
    const res = await getApplications({}); // Reload applications with no filters
    setApplications(res);
  };

  const applyFilters = async () => {
    onFilterChange();
    if (
      selectedAppStatus.length > 0 ||
      intakeYear ||
      intakeMonth ||
      confrimStatus
    ) {
      setfilterApplied(true);
    } else {
      setfilterApplied(false);
    }
    const res = await getApplications({
      status: selectedAppStatus.length > 0 ? selectedAppStatus : undefined,
      intakeYear,
      intakeMonth,
      confrimStatus: confrimStatus.length > 0 ? confrimStatus : undefined,
    });
    setApplications(res);
    setShowDropdown(false); // Close the dropdown after applying filters
  };

  return (
    <div className="d-flex flex-row align-items-center">
      <Dropdown
        show={showDropdown}
        onToggle={() => setShowDropdown(!showDropdown)}
      >
        <Dropdown.Toggle as={FilterBtn} filterApplied={filterApplied}>
          {filterApplied ? (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FilterIcon color={"#98A2B3"} style={{ marginRight: 5 }} />
                Edit Applications
              </div>
            </>
          ) : (
            <>
              <FilterIcon color={"#98A2B3"} style={{ marginRight: 5 }} />
              Filter Applications
            </>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu className="filter-dd-menu">
          <Row className="mb-3">
            <Col>
              <Label>Select Phases:</Label>
              <DropdownButton
                id="phases-dropdown"
                title={
                  selectedAppStatus.length > 0
                    ? selectedAppStatus.join(", ")
                    : "Select Phases"
                }
                variant="secondary"
              >
                {phases.map((phase) => (
                  <Dropdown.Item
                    key={phase}
                    active={selectedAppStatus.includes(phase)}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent closing the main dropdown
                      handlePhaseChange(phase);
                    }}
                  >
                    {phase}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Label>Select Intake Year:</Label>
              <Form.Control
                as="select"
                value={intakeYear || ""}
                onChange={(e: any) => handleYearChange(e)}
              >
                <option value="">Select</option>
                {[...Array(10)].map((_, i) => {
                  const year = new Date().getFullYear() + i;
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Label>Select Intake Month:</Label>
              <Form.Control
                as="select"
                value={intakeMonth || ""}
                onChange={(e: any) => handleMonthChange(e)}
              >
                <option value="">Select</option>
                {[...Array(12)].map((_, i) => {
                  const month = new Date(0, i).toLocaleString("default", {
                    month: "long",
                  });
                  return (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Label>Select Status:</Label>
              <Form.Control
                as="select"
                value={confrimStatus || ""}
                onChange={(e: any) => handleStatusChange(e)}
              >
                <option value="">Select</option>
                {statuses.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Row>

          <ButtonComponent
            onClick={applyFilters}
            label={"Apply Filters"}
            loading={false}
            btnType={"dark"}
          />
        </Dropdown.Menu>
      </Dropdown>
      {filterApplied ? (
        <div
          style={{ marginLeft: 15, cursor: "pointer" }}
          onClick={resetFilters}
        >
          <DeleteIcon />
        </div>
      ) : null}
    </div>
  );
};
